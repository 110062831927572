@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#0D0D0D
}

html, body {
    height: 100%;
    overflow: hidden;
}

#main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    overscroll-behavior: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.am5stock-control-button{
  border:none !important
}

.am5stock-link{
  padding:6px 1rem !important;
  font-family: Poppins !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 12px !important;
line-height: 18px !important;
color:#767677 !important

}

.am5stock-link.am5stock-active, .am5stock-link:hover {
  background: #211548 !important;
  padding: 6px 1rem !important;
  border-radius: 10px !important;
  color:#A489FF  !important;

}

.am5stock-disabledBtn {
  pointer-events: none !important;
  color:#3b3b3c !important
}
@keyframes AnimationName {
  0%{background-position:100% 50%}
  100%{background-position:0% 50%}
}



.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border:none !important
}

.css-o4b71y-MuiAccordionSummary-content{
  display: flex;
  justify-content: space-between;
}

.btn-danger {
    padding: 6px 16px;
    background-color: #8264E5 !important;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(7 41 244 / 40%) 0px 0px 8px !important;
    margin-left: 8px !important;
    text-decoration: none !important;
    width: 80px !important;
    color:#fff  !important;
}

element.style {
    font-weight: 800;
    margin: auto !important;
}

.btn-lg {
    width: 100% !important;
    margin-top: 8px !important;
    padding: 10px !important;
    border-radius: 16px;
}
.swal-button {
    padding: 7px 19px;
    border-radius: 2px;
    background-color: #8264E5 !important;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(7 41 244 / 40%) 0px 0px 8px !important; 
    font-size: 12px;
    border: 1px solid #3e549a;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  }

  .btn-primary {
    padding: 8px;
    width:100px;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(7 41 244 / 40%) 0px 0px 8px !important;
    margin-left: 8px !important;
    background-color: #8264E5;
    color:white;
}

.btn {
  text-decoration: none;
}
