@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.am5stock-control-button{
  border:none !important
}

.am5stock-link{
  padding:6px 1rem !important;
  font-family: Poppins !important;
font-style: normal !important;
font-weight: 500 !important;
font-size: 12px !important;
line-height: 18px !important;
color:#767677 !important

}

.am5stock-link.am5stock-active, .am5stock-link:hover {
  background: #211548 !important;
  padding: 6px 1rem !important;
  border-radius: 10px !important;
  color:#A489FF  !important;

}

.am5stock-disabledBtn {
  pointer-events: none !important;
  color:#3b3b3c !important
}


@-webkit-keyframes AnimationName {
  0%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:100% 50%}
  100%{background-position:0% 50%}
}



.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border:none !important
}

.css-o4b71y-MuiAccordionSummary-content{
  display: flex;
  justify-content: space-between;
}
