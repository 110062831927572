.swal-button {
    padding: 7px 19px;
    border-radius: 2px;
    background-color: #8264E5 !important;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(7 41 244 / 40%) 0px 0px 8px !important; 
    font-size: 12px;
    border: 1px solid #3e549a;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  }

  .btn-primary {
    padding: 8px;
    width:100px;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(7 41 244 / 40%) 0px 0px 8px !important;
    margin-left: 8px !important;
    background-color: #8264E5;
    color:white;
}

.btn {
  text-decoration: none;
}