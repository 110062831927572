.btn-danger {
    padding: 6px 16px;
    background-color: #8264E5 !important;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(7 41 244 / 40%) 0px 0px 8px !important;
    margin-left: 8px !important;
    text-decoration: none !important;
    width: 80px !important;
    color:#fff  !important;
}

element.style {
    font-weight: 800;
    margin: auto !important;
}

.btn-lg {
    width: 100% !important;
    margin-top: 8px !important;
    padding: 10px !important;
    border-radius: 16px;
}